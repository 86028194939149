<template>
  <v-layout row wrap>
    <v-flex xs12 md12>
      <v-card>
        <!-- PESQUISA -->
        <v-toolbar flat class="transparent">
          <v-btn color="green" class="white--text" @click="goCadCanal()">Novo Canal</v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            color="primary"
          ></v-text-field>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="elevation-1"
          item-key="id"
          :rows-per-page-items="[5,10,20,50,100]"
          dense
        >
          <template slot="no-data">
            <div class="text-xs-center">Sem registros.</div>
          </template>
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.codigo }}</td>
              <td>{{ props.item.razao_social }}</td>
              <td>{{ props.item.nome_fantasia }}</td>
              <td>{{ props.item.cnpj_format }}</td>
              <td>{{ props.item.data_cadastro_format }}</td>
              <td class="text-xs-center">
                <v-menu offset-y>
                  <v-icon small class="mr-2 td-cursor" slot="activator">menu</v-icon>
                  <v-list>
                    <v-list-tile @click="editUnidade(props.item)">
                      <v-list-tile-title>Editar</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="goUsuarios(props.item)">
                      <v-list-tile-title>Usuários</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="goLojas(props.item)">
                      <v-list-tile-title>Lojas</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <div class="text-xs-center">Sem resultados para a pesquisa "{{ search }}".</div>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>

const canalModule = () => import('./../../../services/unidade')

export default {
  name: 'CanalLista',
  data () {
    return {
      search: '',
      headers: [
        { text: 'Código', align: 'left', value: 'codigo' },
        { text: 'Razão Social', align: 'left', value: 'razao_social' },
        { text: 'Nome Fantasia', align: 'left', value: 'nome_fantasia' },
        { text: 'CNPJ', align: 'left', value: 'cnpj' },
        { text: 'Data de cadastro', align: 'left', value: 'data_cadastro_format' },
        { text: 'Opções', align: 'left', value: '' },
        // { text: 'Usuários', align: 'left', value: '' },
      ],
      items: []
    }
  },
  mounted () {
    this.getAll()
  },
  methods: {
    async getAll () {
      let canalService = await canalModule()
      let resp = await canalService.getAllCanais(this.$axios)
      if (resp.status === 200 && resp.data) {
        this.items = resp.data
      }
    },
    async goCadCanal () {
      this.$router.push('/canal/cad')
    },
    async editUnidade (unidade) {
      let encode = btoa(unidade.codigo)
      this.$router.push(`/canal/cad/${encode}`)
    },
     async goLojas (unidade) {
      let encode = btoa(unidade.codigo)
      this.$router.push(`/loja/${encode}`)
    },
    async goUsuarios(unidade){
      let encode = btoa(unidade.codigo)
      this.$router.push(`/usuario/${encode}`)
    }
  }
}
</script>